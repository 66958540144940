import Logo from '@/assets/images/logo.svg';
import SearchInput from '../../general/SearchInput';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { observer } from 'mobx-react-lite';
import { HEADER_FORMAT } from '@/types/enums';
import { useEffect } from 'react';
import NotificationIcon from '@/components/general/NotificationIcon';
import ChatIcon from '@/components/general/ChatIcon';
import { HouseIcon } from 'lucide-react';

interface HeaderMobileFormat1Props {
}

export default observer(function HeaderMobileFormat1(props: HeaderMobileFormat1Props) {
    // other
    const navigate = useNavigate();
    const location = useLocation();

    // store
    const { uiStore: { isLogoVisible, setShowHeaderFormat, devicesScreen: { mobile } }, authStore: { token } } = useStore();

    // lifecycle
    useEffect(() => {
        setShowHeaderFormat(HEADER_FORMAT.mobile1);

        return () => {
            setShowHeaderFormat(null);
        };
    }, []);

    // function
    const handleGoHomePage = () => {
        navigate(ROUTES.home.href);
    };

    return (
        <div className='w-full flex flex-col'>
            <Link to={ROUTES.home.href} className={`logo h-13 py-2.5 flex justify-center items-center relative ${isLogoVisible ? '' : 'hidden'}`}>
                {
                    location.pathname !== ROUTES.home.href && (
                        <HouseIcon className='h-6 w-6 absolute top-[14px] left-0 stroke-active' onClick={handleGoHomePage}></HouseIcon>
                    )
                }
                <img className='h-8' src={Logo} alt='logo' />
                {
                    token && mobile && location.pathname === ROUTES.nearBy.href && (
                        <div className='flex gap-2.5 items-center absolute top-[14px] right-0'>
                            <ChatIcon />
                            <NotificationIcon />
                        </div>
                    )
                }
            </Link>

            <div className='search-input h-14 py-2.5 flex items-center w-full gap-4'>
                <div className='flex-1 w-full'>
                    <SearchInput className='flex-1 w-full h-10 border-[0.5px] border-input-2 text-icon-1' />
                </div>
                {token && location.pathname !== ROUTES.nearBy.href && (
                    <>
                        <div className='flex gap-2.5 items-center'>
                            <ChatIcon />
                            <NotificationIcon />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
});
