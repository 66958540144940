import { MultiPetPrintIcon } from '@/components/icons';
import { useStore } from '@/hooks/useStore';
import { ChevronRight } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import AllShop from '../components/AllShop';
import Banner from '../components/Banner';
import TopService from '../components/TopService';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '@/configs/constants';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export default observer(function GeneralHome() {
    // hook
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname, hash } = useLocation();

    // store
    const { uiStore: { setObservable, devicesScreen: { mobile } }, shopStore: { topService } } = useStore();

    // lifecycle
    useEffect(() => {
        !mobile && isTopService && navigate(ROUTES.home.href, { replace: true });
        return () => {
            setObservable('isLogoVisible', true);
        };
    }, [mobile]);

    // function
    const handelNavigateTopService = () => {
        navigate('#topservice');
    };

    // Check is on view-all page mobile of topservice section
    const isTopService = pathname === ROUTES.home.href && hash === '#topservice';

    return (
        (mobile && isTopService) ?
            <TopService isViewAll={true} /> :
            (
                <div className='md:pt-4'>
                    <div className='banner bg-white p-4 md:rounded-md'>
                        <Banner />
                    </div>
                    <div className='main flex flex-col gap-4'>
                        <section className='top-service mt-4 md:bg-white bg-background-2 md:p-4 md:pt-4 md:rounded-md'>
                            <div className='bg-white md:pt-0 pt-3 flex justify-between md:px-0 px-4'>
                                <div className='title relative bg-white h-10 w-fit py-1 md:pl-0'>
                                    <h3 className='text-xl font-semibold'>{t('words_title.top_service')}</h3>
                                    <div className='absolute -top-[0.125rem] -right-4'>
                                        <MultiPetPrintIcon className='w-6 h-6 fill-black' />
                                    </div>
                                </div>
                                <div onClick={() => handelNavigateTopService()} className={classNames('flex gap-[0.125rem] cursor-pointer items-center md:hidden', !(topService?.length > 2) && 'hidden')}>
                                    <span>{t('button.view_all')}</span>
                                    <ChevronRight className='text-black' />
                                </div>
                            </div>
                            <div>
                                <TopService />
                            </div>
                        </section>
                        <div className='all-shop md:p-4 md:bg-white bg-background-2 md:rounded-md'>
                            <AllShop />
                        </div>
                    </div>
                </div>
            )
    );
});
