import { useStore } from '@/hooks/useStore';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import SheetFilter from '../home/components/SheetFilter';
import SortShop from '../home/components/SortShop';
import Paginate from '@/components/pagination/Paginate';
import { useEffect } from 'react';
import SearchInput from '@/components/general/SearchInput';
import { ChevronLeftIcon } from 'lucide-react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ROUTES, SORT_OPTION } from '@/configs/constants';
import { convertParamsToApiParams } from '@/utils/utils';
import { TableState } from '@tanstack/react-table';
import StoreCard from '../home/components/StoreCard';
import { flowResult } from 'mobx';
import { useTranslation } from 'react-i18next';

export default observer(function SearchResult() {
    // hook
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // store
    const { uiStore: { setObservable, currentLocation, devicesScreen: { mobile } }, shopStore: { allShop, getAllShop, cleanAllShop, paging, totalPage, setObservable: setVariableObservable } } = useStore();

    // lifecycle
    useEffect(() => {
        if (mobile) {
            setObservable('isLogoVisible', false, { isMergeObject: false });
        } else {
            setObservable('isLogoVisible', true, { isMergeObject: false });
        }
    }, [mobile]);
    useEffect(() => {
        searchParams && getDataAllShop(searchParams, paging);
    }, [searchParams, currentLocation]);

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setObservable('currentLocation', {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                }, { isMergeObject: true });
            });
        }

        return () => {
            cleanAllShop();
        };
    }, []);

    // function
    const getDataAllShop = async (searchParams: URLSearchParams, paging: TableState) => {
        let params = convertParamsToApiParams(searchParams);
        let pagingReq: TableState = { ...paging };
        pagingReq.pagination.pageSize = 18;
        if (currentLocation) {
            params.location = currentLocation.latitude + ',' + currentLocation.longitude;
            pagingReq = {
                ...pagingReq,
                sorting: [
                    {
                        id: SORT_OPTION.Nearby.value,
                        desc: true
                    }
                ]
            };
        } else {
            pagingReq = {
                ...pagingReq,
                sorting: [
                    {
                        id: SORT_OPTION.StarRate.value,
                        desc: true
                    }
                ]
            };
        }
        if (searchParams.get('sort')) {
            pagingReq = {
                ...pagingReq,
                sorting: [
                    {
                        id: searchParams.get('sort') || '',
                        desc: true
                    }
                ]
            };
        }
        if (searchParams.get('keyword')) {
            params.name = searchParams.get('keyword');
        }

        if (currentLocation) {
            params.location = currentLocation.latitude + ',' + currentLocation.longitude;
        }
        params.isGetNearBy = false;

        const res = await flowResult(getAllShop(params, pagingReq));
        if (res) {
            setVariableObservable('allShop', res.elements, { isMergeObject: false });
        }
    };
    // function
    const handleRedirectBack = () => {
        navigate(ROUTES.home.href);
    };

    return (
        <div className='md:pt-8'>
            <div className='min-h-[calc(100vh-320px)] md:p-4 rounded-md md:bg-white bg-background-2'>
                {mobile && (
                    <div className='bg-white fixed top-0 left-0 search-input h-14 py-2.5 flex items-center w-full gap-4 z-50 p-4'>
                        <div className='' onClick={() => handleRedirectBack()}>
                            <ChevronLeftIcon className='w-6 h-6 text-black cursor-pointer' />
                        </div>
                        <div className='flex-1 w-full'>
                            <SearchInput className='flex-1 w-full h-10 border-[0.5px] border-input-2 text-icon-1' />
                        </div>
                    </div>
                )}
                <div className=''>
                    <div className='flex justify-between bg-white'>
                        <div
                            className='h-10 w-fit py-1 md:block hidden'
                        >
                            <h3 className='text-xl font-semibold'>{t('words_title.search_pet_shop')}</h3>
                        </div>
                        <div
                            className={classNames(
                                'md:h-8 h-12 bg-white flex md:justify-end justify-start gap-2 md:gap-0',
                                mobile && 'fixed container w-screen top-[56px] left-0 px-4 py-1 z-50'
                            )}
                        >
                            <SheetFilter />
                            <div className='w-[1px] h-full bg-border-6 mx-1 md:block hidden'></div>
                            <SortShop />
                        </div>
                    </div>
                    <div className='md:mt-2 mt-13'>
                        {allShop.length > 0 ?
                            (
                                <>
                                    <div className='grid grid-flow-row 2xl:grid-cols-3 point-920:grid-cols-2 grid-cols-1 2xl:gap-2 md:gap-4 gap-1'>
                                        {allShop.map((store, index) => {
                                            return (
                                                <StoreCard key={index} storeCard={store} callBackUpdateReviewFc={() => getDataAllShop(searchParams, paging)} />
                                            );
                                        })}
                                    </div>
                                    <div className='flex justify-center mt-2 bg-white py-2'>

                                        <Paginate
                                            forcePage={paging.pagination.pageIndex}
                                            pageCount={totalPage}
                                            onPageChange={({ selected }) => {
                                                paging.pagination.pageIndex = selected;
                                                getDataAllShop(searchParams, paging);
                                            }}
                                        />
                                    </div>
                                </>
                            ) :
                            (<div className='flex justify-center bg-white items-center md:h-28 h-[calc(100vh-320px)] px-4 w-full'>{t('sentences.no_shop_found')}</div>)}
                    </div>
                </div>
            </div>
        </div>
    );
});
