import { CallIconButton, ClockIcon, DoubleMessageIcon, LocationIcon, PetHospitalIcon, PetPrintIcon, PickupIcon } from '@/components/icons';
import { useStore } from '@/hooks/useStore';
import classNames from 'classnames';
import { CalendarClock, DotIcon, Heart, Star, TriangleAlert } from 'lucide-react';
import { ShopCardInfo } from '@/types/shop';
import { ACTIVITY_PATH, DISPLAY_MOMENT_TIME_HM_AM_PM, DISPLAY_MOMENT_TIME_HMS, ROUTES, SHOP_CATEGORY } from '@/configs/constants';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { formatDateTime } from '@/utils/datetime';
import ShopImg from '@/assets/images/defaultShopImg.png';
import { formatVietnamPhoneNumber, truncateString } from '@/utils/utils';
import { SHOP_CATEGORY_TYPE } from '@/types/enums';
import { useMemo } from 'react';
import WriteReview from '@/assets/images/writeReviewStoreCard.svg';
import ReviewForm from '@/pages/shop-detail/components/components/ReviewForm';

interface StoreCardInfoProps {
    storeCard: ShopCardInfo,
    callBackUpdateFavoriteFc?: Function,
    callBackUpdateReviewFc?: Function
}

export default observer(function StoreCard({ storeCard, callBackUpdateFavoriteFc, callBackUpdateReviewFc = () => { } }: StoreCardInfoProps) {
    // props
    const { name, address, rating, isFavorite, generalOpenTime, generalCloseTime,
        topReview, pickUpStatus, category, slug, id, code, operatingStatus, totalReviews, image, generalInfo, shopInfo, operationInfo, appointmentInfo, phoneNumber } = storeCard;

    // hook
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const reviewList = useMemo(() => {
        if (topReview?.length > 0) {
            if (topReview[0].mark_top) {
                return [topReview[0]];
            } else {
                return topReview;
            }
        }
    }, [topReview]);

    // store
    const { authStore: { token, profile }, shopStore: { updateFavoriteShops, setAllShop, allShop, topService, getShopDetail, setObservable },
        petStore: { getAllPet }, modalStore: { showAlertModal, showModal }, uiStore: { windowSize } } = useStore();
    // function
    const handleAddToFavoriteShops = async (slugShop: string, value: boolean) => {
        const res = await flowResult(updateFavoriteShops({ slug: slugShop, favorite: value }));
        if (res) {
            const findShopInAllShop = allShop.findIndex(item => item.slug === slugShop);
            const findShopInTopService = topService.findIndex(item => item.slug === slugShop);
            if (findShopInAllShop > -1) {
                let cloneAllShop = [...allShop];
                cloneAllShop[findShopInAllShop].isFavorite = value;
                setAllShop(cloneAllShop);
            }
            if (findShopInTopService > -1) {
                let cloneTopShop = [...topService];
                cloneTopShop[findShopInTopService].isFavorite = value;
                setObservable('topService', cloneTopShop, { isMergeObject: false });
            }

            callBackUpdateFavoriteFc && callBackUpdateFavoriteFc(res);
        }
    };

    const handleRedirectBookAppointment = async () => {
        if (!token) navigate(ROUTES.signIn.href);
        if (token && operatingStatus && generalInfo && shopInfo && operationInfo && appointmentInfo) {
            const res = await flowResult(getAllPet());
            if (res) {
                if (res.length > 0) {
                    navigate(`${ROUTES.bookAppointment.href}/${slug}`, {
                        state: {
                            previousUrl: pathname
                        }
                    });
                } else {
                    showAlertModal({
                        id: 'pet-modal',
                        type: 'error',
                        content: t('sentences.not_any_pet')
                    });
                }
            }
        }
    };

    const handleRedirectChatScreen = () => {
        !token ? navigate(ROUTES.signIn.href) : navigate(`${ROUTES.activity.href}/${ACTIVITY_PATH.chat}/${slug}`);
    };

    const handlePostReview = () => {
        if (!profile) {
            navigate(ROUTES.signIn.href);
            return;
        }
        showModal({
            id: 'write-review',
            title: t('words_title.write_review'),
            titleClassName: 'title-3 text-center',
            content: <ReviewForm homeSlug={slug} onUpdateReview={callBackUpdateReviewFc} />,
            footerClassName: 'p-0'
        });
    };

    const handleClickReviewDetail = () => {
        navigate(`${ROUTES.shopDetail.href}/${slug}#review`);
    };

    return (
        <div className='2xl:px- py-4 px-4 w-full min-w-[20rem] md:rounded-sm bg-white md:border border-solid border-border-8 h-full flex flex-col justify-between'>
            <div className='shop-info min-h-[12.5rem] w-full pb-1 flex point-450:flex-row flex-col xsm:items-start items-center gap-4'>
                <div className='relative cursor-pointer w-full point-450:w-[11.625rem]' onClick={() => navigate(`${ROUTES.shopDetail.href}/${slug}`)}>
                    {!operatingStatus && (
                        <div className='absolute flex flex-col gap-1 justify-center items-center top-0 left-0 w-full h-full rounded-[0.25rem] bg-black/60'>
                            <TriangleAlert className='text-white size-[1.1875rem]' />
                            <p className='text-white font-medium text-center'>{t('sentences.temporarily_out_of_service')}</p>
                        </div>
                    )}
                    <img src={image || ShopImg} className='w-full point-450:size-[11.625rem] aspect-square object-cover rounded-[0.25rem]' alt='shop avartar' />
                </div>
                <div className='py-2 flex flex-col gap-2 flex-1 overflow-hidden w-full'>
                    <div className='min-h-7 flex justify-between items-center gap-2'>
                        <div className='h-full min-h-7 bg-background-3 border-l border-l-icon-4 rounded-[0.125rem] flex items-center justify-start px-2 gap-1'>
                            {category === SHOP_CATEGORY_TYPE.PetHospital ? <PetHospitalIcon className='h-5 w-5 fill-active' /> : <PetPrintIcon className='h-4 w-4 fill-icon-4' />}
                            <p className='text-tab-1'>{category ? t(`select_options.${SHOP_CATEGORY[category].key}`) : ''}</p>
                        </div>
                        {token && <div onClick={() => handleAddToFavoriteShops(slug, !isFavorite)}><Heart className={classNames('w-5 h-5 cursor-pointer', isFavorite ? 'text-active fill-active' : 'text-black fill-white')} strokeWidth={1} /></div>}
                    </div>
                    <div className='line-clamp-2'>
                        <p onClick={() => navigate(`${ROUTES.shopDetail.href}/${slug}`)} className='text-[1.0625rem] font-medium cursor-pointer hover:underline leading-6'>{name}</p>
                    </div>
                    <div className='flex gap-1'>
                        <div className='pt-1 size-[0.8125rem]'>
                            <LocationIcon className='size-[0.8125rem] fill-icon-2' />
                        </div>
                        <p className='text-tab-1 break-words text-small line-clamp-2'>{address}</p>
                    </div>
                    <div className='flex items-center gap-1'>
                        <div className='size-[0.8125rem]'>
                            <ClockIcon className='size-[0.8125rem] fill-icon-2' />
                        </div>
                        <p className='text-tab-1 text-small break-words'>
                            {formatDateTime(generalOpenTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                onlyTime: true,
                                currentformat: DISPLAY_MOMENT_TIME_HMS
                            })}
                            {' '}
                            -
                            {' '}
                            {formatDateTime(generalCloseTime, DISPLAY_MOMENT_TIME_HM_AM_PM, {
                                onlyTime: true,
                                currentformat: DISPLAY_MOMENT_TIME_HMS
                            })}
                        </p>
                    </div>
                    <div className='flex gap-1 items-center'>
                        <div
                            className='flex items-center gap-1 cursor-pointer'
                            onClick={handleClickReviewDetail}
                        >
                            <p className='text-icon-3 text-small'>{rating.toFixed(1) || '0.0'}</p>
                            <Star className='text-icon-3 w-4 h-4' fill='#FFB72C' />
                            <p className='text-tab-1 text-small'>
                                (
                                {totalReviews || 0}
                                )
                            </p>
                        </div>
                        <div className={classNames('flex gap-1 items-center w-fit h-7', !pickUpStatus && 'opacity-0')}>
                            <DotIcon className='fill-text-14 w-3 h-3' />
                            <PickupIcon className='fill-text-14 w-4 h-3' />
                            <p className='text-small text-text-14'>{t('words_title.mark_pick_up')}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='comment border-dashed border-t border-border-8 flex gap-3 min-h-[64px]'>
                    {
                        reviewList?.length > 0 && (
                            <div
                                className='gap-1 py-3 overflow-hidden text-nav-1 text-small cursor-pointer'

                                onClick={handleClickReviewDetail}
                            >
                                {
                                    reviewList.map((review, index) => (
                                        <p key={index} className='text-small text-tab-1 italic whitespace-pre-line'>{truncateString(review?.comment)}</p>
                                    ))
                                }
                            </div>
                        )
                    }
                </div>
                <div className='grid grid-cols-4 border-t border-border-8 justify-center items-center h-fit pt-4 '>
                    <div
                        title={windowSize.width <= 920 ? t('button.make_a_call') : t('sentences.not_support_pc')}
                        className={classNames('border-r border-border-4 border-dashed flex items-center justify-center', windowSize.width <= 920 ? 'cursor-pointer' : 'cursor-not-allowed')}
                    >
                        {
                            windowSize.width <= 920 ?
                                (
                                    <a href={phoneNumber ? `tel:${formatVietnamPhoneNumber(phoneNumber)}` : ''} target='_blank' rel='noreferrer'>
                                        <CallIconButton className='w-6 h-6 text-active stroke-active fill-none' />
                                    </a>
                                ) :
                                <CallIconButton className='w-6 h-6 text-active stroke-active fill-none opacity-50' />
                        }
                    </div>
                    <div
                        onClick={() => handleRedirectChatScreen()}
                        title={t('button.chat_tooltip')}
                        className='border-r border-border-4 border-dashed flex items-center justify-center cursor-pointer'
                    >
                        <DoubleMessageIcon className='w-6 h-6 stroke-active fill-none' />
                    </div>
                    <div
                        title={(!token || (token && operatingStatus && generalInfo && shopInfo && operationInfo && appointmentInfo)) ? t('button.make_appointment') : t('sentences.function_not_available')}
                        onClick={() => handleRedirectBookAppointment()}
                        aria-disabled={!(token && operatingStatus && generalInfo && shopInfo && operationInfo && appointmentInfo)}
                        className={classNames(
                            'border-r border-border-4 border-dashed flex items-center justify-center cursor-pointer',
                            {
                                'disabled:opacity-20 !cursor-not-allowed': token && !(operatingStatus && generalInfo && shopInfo && operationInfo && appointmentInfo)
                            }
                        )}
                    >
                        <CalendarClock strokeWidth={1.25} className={classNames('w-6 h-6 stroke-active fill-none', token && !(operatingStatus && generalInfo && shopInfo && operationInfo && appointmentInfo) && 'opacity-50')} />
                    </div>
                    <div
                        className='flex items-center justify-center cursor-pointer'
                        onClick={handlePostReview}
                    >
                        <div className='w-6 h-6 stroke-active fill-none'>
                            <img src={WriteReview} alt='' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});
