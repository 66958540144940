import { cn } from '@/utils/utils';
import PetNote from '@/assets/images/petNote.svg';
import PetNoteActive from '@/assets/images/petNoteActive.svg';
import Activity from '@/assets/images/activity.svg';
import ActivityActive from '@/assets/images/activityActive.svg';
import Forum from '@/assets/images/forum.svg';
import ForumActive from '@/assets/images/forumActive.svg';
import MyAccount from '@/assets/images/myAccount.svg';
import MyAccountActive from '@/assets/images/myAccountActive.svg';

export const PetNoteIcon = ({ isActive = false, className = '' }) => {
    return (
        <img className={cn('w-5 h-5', className)} src={!isActive ? PetNote : PetNoteActive} alt='pet-note-icon' />
    );
};

export const ActivityIcon = ({ isActive = false, className = '' }) => {
    return (
        <img className={cn('w-5 h-5', className)} src={!isActive ? Activity : ActivityActive} alt='pet-note-icon' />
    );
};

export const ForumIcon = ({ isActive = false, className = '' }) => {
    return (
        <img className={cn('w-5 h-5', className)} src={!isActive ? Forum : ForumActive} alt='pet-note-icon' />
    );
};

export const MyAccountIcon = ({ isActive = false, className = '' }) => {
    return (
        <img className={cn('w-5 h-5', className)} src={!isActive ? MyAccount : MyAccountActive} alt='pet-note-icon' />
    );
};

export const LocationIcon = ({ className = '' }) => {
    return (
        <div className={className}>
            <svg className='w-full h-full' viewBox='0 0 13 16' xmlns='http://www.w3.org/2000/svg'>
                <path d='M6.5 0.355469C4.95071 0.357297 3.46539 0.973563 2.36987 2.06908C1.27435 3.1646 0.658081 4.64992 0.656254 6.19922C0.654829 7.46524 1.06835 8.69684 1.8335 9.70547C1.8335 9.70547 1.99288 9.91531 2.01891 9.94559L6.5 15.2305L10.9832 9.94294C11.0066 9.91478 11.1665 9.70547 11.1665 9.70547L11.167 9.70387C11.9317 8.69561 12.345 7.46463 12.3438 6.19922C12.3419 4.64992 11.7257 3.1646 10.6301 2.06908C9.53462 0.973563 8.0493 0.357297 6.5 0.355469ZM6.5 8.32422C6.07972 8.32422 5.66887 8.19959 5.31942 7.96609C4.96996 7.73259 4.6976 7.40071 4.53676 7.01242C4.37592 6.62413 4.33384 6.19686 4.41583 5.78465C4.49783 5.37244 4.70021 4.9938 4.9974 4.69662C5.29459 4.39943 5.67323 4.19704 6.08544 4.11505C6.49765 4.03306 6.92491 4.07514 7.31321 4.23597C7.7015 4.39681 8.03338 4.66918 8.26688 5.01863C8.50037 5.36809 8.625 5.77893 8.625 6.19922C8.6243 6.76259 8.40019 7.30268 8.00183 7.70104C7.60347 8.09941 7.06337 8.32352 6.5 8.32422Z' />
            </svg>

        </div>
    );
};

export const ClockIcon = ({ className = '' }) => {
    return (
        <div className={className}>
            <svg className='w-full h-full' viewBox='0 0 13 13' xmlns='http://www.w3.org/2000/svg'>
                <path d='M6.50009 0.754395C9.75017 0.754395 12.3847 3.38894 12.3847 6.63901C12.3847 9.88908 9.75017 12.5236 6.50009 12.5236C3.25002 12.5236 0.615479 9.88908 0.615479 6.63901C0.615479 3.38894 3.25002 0.754395 6.50009 0.754395ZM6.50009 3.10824C6.34402 3.10824 6.19435 3.17024 6.08399 3.2806C5.97363 3.39095 5.91163 3.54063 5.91163 3.6967V6.63901C5.91167 6.79507 5.97369 6.94472 6.08405 7.05505L7.84944 8.82044C7.96042 8.92763 8.10907 8.98694 8.26336 8.9856C8.41765 8.98426 8.56525 8.92237 8.67435 8.81327C8.78346 8.70416 8.84535 8.55657 8.84669 8.40228C8.84803 8.24798 8.78871 8.09934 8.68152 7.98835L7.08856 6.39539V3.6967C7.08856 3.54063 7.02656 3.39095 6.9162 3.2806C6.80584 3.17024 6.65616 3.10824 6.50009 3.10824Z' />
            </svg>

        </div>
    );
};

export const PetPrintIcon = ({ className = '' }) => {
    return (
        <div className={className}>
            <svg className='w-full h-full' viewBox='0 0 13 11' xmlns='http://www.w3.org/2000/svg'>
                <g clipPath='url(#clip0_3026_11899)'>
                    <path d='M7.37833 4.43802C7.07596 4.37561 6.7832 4.34335 6.49999 4.33936C6.21686 4.34335 5.92402 4.37553 5.62164 4.43802C3.28076 4.92127 1.43488 7.82842 2.44092 9.46414C2.89776 10.2069 3.82724 10.3623 4.66143 9.8354C5.52423 9.29045 6.01533 9.01731 6.49999 9.01128C6.98455 9.01731 7.47574 9.29045 8.33846 9.8354C9.17274 10.3623 10.1021 10.2069 10.559 9.46414C11.5651 7.82842 9.71912 4.92127 7.37825 4.43802H7.37833Z' />
                    <path d='M3.07148 4.45425C2.96515 3.77179 2.7223 3.10647 2.00839 2.63215C1.18965 2.08814 0.399967 2.28235 0.100149 3.10381C-0.191721 3.9035 0.189167 4.58205 0.792865 5.18808C1.1711 5.56781 1.66583 5.86421 2.29452 5.6541C2.90007 5.45183 3.01505 4.96811 3.07139 4.45433L3.07148 4.45425Z' />
                    <path d='M5.8069 3.2169C6.17543 2.69732 6.25376 2.13302 6.0842 1.55048C5.82139 0.647262 5.21133 0.0964352 4.54511 0.150077C3.88922 0.202936 3.44165 0.867702 3.44192 1.86747C3.39361 2.33177 3.5856 2.82504 3.95642 3.2667C4.49106 3.90351 5.33602 3.8808 5.80699 3.2169H5.8069Z' />
                    <path d='M12.9 3.10381C12.6002 2.28235 11.8105 2.08814 10.9918 2.63215C10.278 3.10655 10.035 3.77179 9.92871 4.45425C9.98505 4.96804 10.1 5.45175 10.7057 5.65402C11.3345 5.86405 11.8291 5.56765 12.2073 5.18801C12.811 4.58205 13.1919 3.90342 12.9 3.10373V3.10381Z' />
                    <path d='M9.04424 3.2667C9.41507 2.82504 9.60705 2.33169 9.55875 1.86747C9.55901 0.867702 9.11145 0.202936 8.45547 0.150077C7.78916 0.0964352 7.17919 0.647262 6.91638 1.55048C6.74691 2.13302 6.82515 2.69732 7.19368 3.2169C7.66464 3.8808 8.50961 3.90343 9.04424 3.2667Z' />
                </g>
                <defs>
                    <clipPath id='clip0_3026_11899'>
                        <rect width='13' height='10' fill='white' transform='translate(0 0.146484)' />
                    </clipPath>
                </defs>
            </svg>

        </div>
    );
};

export const PickupIcon = ({ className = '' }) => {
    return (
        <div className={className}>
            <svg className='w-full h-full' viewBox='0 0 16 10' xmlns='http://www.w3.org/2000/svg'>
                <g clipPath='url(#clip0_3026_11932)'>
                    <path d='M15.6 6.875H15.2V4.76367C15.2 4.51562 15.0725 4.27734 14.8475 4.10156L12.35 2.15039C12.125 1.97461 11.82 1.875 11.5025 1.875H10.4V0.9375C10.4 0.419922 9.8625 0 9.2 0H2.8C2.1375 0 1.6 0.419922 1.6 0.9375V1.875H0.2C0.09 1.875 0 1.94531 0 2.03125V2.34375C0 2.42969 0.09 2.5 0.2 2.5H7C7.11 2.5 7.2 2.57031 7.2 2.65625V2.96875C7.2 3.05469 7.11 3.125 7 3.125H1C0.89 3.125 0.8 3.19531 0.8 3.28125V3.59375C0.8 3.67969 0.89 3.75 1 3.75H6.2C6.31 3.75 6.4 3.82031 6.4 3.90625V4.21875C6.4 4.30469 6.31 4.375 6.2 4.375H0.2C0.09 4.375 0 4.44531 0 4.53125V4.84375C0 4.92969 0.09 5 0.2 5H5.4C5.51 5 5.6 5.07031 5.6 5.15625V5.46875C5.6 5.55469 5.51 5.625 5.4 5.625H1.6V8.125C1.6 9.16016 2.675 10 4 10C5.325 10 6.4 9.16016 6.4 8.125H9.6C9.6 9.16016 10.675 10 12 10C13.325 10 14.4 9.16016 14.4 8.125H15.6C15.82 8.125 16 7.98438 16 7.8125V7.1875C16 7.01562 15.82 6.875 15.6 6.875ZM4 9.0625C3.3375 9.0625 2.8 8.64258 2.8 8.125C2.8 7.60742 3.3375 7.1875 4 7.1875C4.6625 7.1875 5.2 7.60742 5.2 8.125C5.2 8.64258 4.6625 9.0625 4 9.0625ZM12 9.0625C11.3375 9.0625 10.8 8.64258 10.8 8.125C10.8 7.60742 11.3375 7.1875 12 7.1875C12.6625 7.1875 13.2 7.60742 13.2 8.125C13.2 8.64258 12.6625 9.0625 12 9.0625ZM14 5H10.4V2.8125H11.5025L14 4.76367V5Z' />
                </g>
                <defs>
                    <clipPath id='clip0_3026_11932'>
                        <rect width='16' height='10' fill='white' />
                    </clipPath>
                </defs>
            </svg>

        </div>
    );
};
export const MultiPetPrintIcon = ({ className = '' }) => {
    return (
        <div className={className}>
            <svg className='w-full h-full' viewBox='0 0 27 21' xmlns='http://www.w3.org/2000/svg'>
                <g clipPath='url(#clip0_610_2198)'>
                    <path d='M19.6888 12.7346C19.6441 12.4005 19.5696 12.0884 19.4675 11.7977C19.3566 11.5103 19.2109 11.2244 19.028 10.9412C17.6131 8.74832 13.7036 8.04845 12.273 9.74202C11.6233 10.5111 11.801 11.5233 12.6976 12.1602C13.6248 12.819 14.1116 13.209 14.3006 13.7014C14.4761 14.1985 14.3588 14.8112 14.0806 15.914C13.8116 16.9805 14.3331 17.8659 15.3264 18.0297C17.5139 18.3903 20.0348 15.3212 19.6887 12.7345L19.6888 12.7346Z' />
                    <path d='M18.0511 8.34379C18.7659 7.95716 19.4104 7.43814 19.6665 6.51604C19.9602 5.45853 19.4484 4.73148 18.4271 4.76012C17.4329 4.78801 16.8257 5.45334 16.3825 6.31659C16.1048 6.85746 15.9631 7.48329 16.4319 8.0395C16.8834 8.57528 17.4616 8.49557 18.051 8.34374L18.0511 8.34379Z' />
                    <path d='M20.4484 10.6325C21.1617 10.797 21.8152 10.647 22.3957 10.2365C23.2958 9.6002 23.6755 8.75295 23.3656 8.09467C23.0605 7.44662 22.1569 7.26058 21.0513 7.66823C20.5197 7.8081 20.0463 8.20509 19.6973 8.76362C19.1941 9.56891 19.5371 10.4223 20.4485 10.6326L20.4484 10.6325Z' />
                    <path d='M23.2414 17.8275C24.0371 17.1867 23.9549 16.3014 23.0453 15.6872C22.2521 15.1518 21.425 15.1748 20.6303 15.3444C20.0832 15.6113 19.5915 15.9257 19.5956 16.6264C19.5998 17.3539 20.1137 17.7381 20.6758 17.9696C21.573 18.339 22.4668 18.4513 23.2415 17.8275L23.2414 17.8275Z' />
                    <path d='M21.6108 13.9571C22.2388 14.1557 22.8566 14.1507 23.3519 13.9122C24.4577 13.5051 25.0245 12.7773 24.8363 12.0861C24.645 11.384 23.8064 10.9857 22.7086 11.0855C22.0006 11.1498 21.4059 11.4596 20.9699 12.0476C20.4128 12.7989 20.7055 13.6707 21.6108 13.9571Z' />
                </g>
                <g clipPath='url(#clip1_610_2198)'>
                    <path d='M7.29867 5.11747C7.04737 5.014 6.79953 4.94019 6.55538 4.89428C6.31001 4.85586 6.0517 4.84239 5.78025 4.85588C3.67894 4.95981 1.61445 7.40717 2.22017 9.08865C2.49522 9.85219 3.27355 10.1362 4.0796 9.76695C4.91328 9.38514 5.38183 9.2025 5.80172 9.26902C6.21959 9.34682 6.60009 9.67573 7.25787 10.3145C7.89399 10.9321 8.72239 10.925 9.23708 10.2975C10.3706 8.91564 9.24399 5.91853 7.29859 5.11745L7.29867 5.11747Z' />
                    <path d='M3.57321 4.49106C3.5914 3.83618 3.48881 3.17702 2.94825 2.62657C2.32832 1.99524 1.61443 2.0595 1.22276 2.78407C0.841475 3.48943 1.06124 4.18153 1.48529 4.83891C1.75097 5.2508 2.13077 5.60202 2.70808 5.4989C3.26412 5.39966 3.44154 4.96384 3.57312 4.49112L3.57321 4.49106Z' />
                    <path d='M6.13727 3.73959C6.53963 3.30794 6.69836 2.7912 6.64578 2.22047C6.56432 1.33557 6.12586 0.728938 5.54135 0.679962C4.9659 0.631791 4.47181 1.18763 4.31076 2.12384C4.1941 2.55141 4.28048 3.04189 4.52976 3.51068C4.88915 4.18659 5.62317 4.29115 6.13735 3.73961L6.13727 3.73959Z' />
                    <path d='M12.2865 4.69012C12.1599 3.87627 11.5087 3.57683 10.7132 3.96432C10.0197 4.30225 9.70237 4.88899 9.50038 5.51221C9.4662 6.0017 9.48755 6.47177 9.97841 6.75136C10.488 7.04166 10.9634 6.83777 11.3516 6.5386C11.9711 6.06108 12.4098 5.48234 12.2865 4.69005L12.2865 4.69012Z' />
                    <path d='M8.92698 4.26839C9.31875 3.91004 9.56429 3.47666 9.59742 3.03478C9.75893 2.09864 9.47931 1.40952 8.92084 1.26234C8.35356 1.11289 7.73747 1.53785 7.3646 2.34448C7.12414 2.86473 7.10074 3.40478 7.33546 3.94619C7.63545 4.638 8.36215 4.78501 8.92698 4.26839Z' />
                </g>
                <defs>
                    <clipPath id='clip0_610_2198'>
                        <rect width='14.1437' height='11.7864' fill='white' transform='translate(21.6602 3.45312) rotate(69.7747)' />
                    </clipPath>
                    <clipPath id='clip1_610_2198'>
                        <rect width='11.4022' height='9.50184' fill='white' transform='translate(1.61328) rotate(9.77468)' />
                    </clipPath>
                </defs>
            </svg>

        </div>
    );
};

export const DoubleMessageIcon = ({ className = '' }) => {
    return (
        <div className={className}>
            <svg className='w-full h-full' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                <g clipPath='url(#clip0_369_3250)'>
                    <path d='M11.6667 7.49837C11.6667 7.9404 11.4912 8.36432 11.1786 8.67688C10.866 8.98944 10.4421 9.16504 10.0001 9.16504H5.00008L1.66675 12.4984V3.33171C1.66675 2.41504 2.41675 1.66504 3.33341 1.66504H10.0001C10.4421 1.66504 10.866 1.84063 11.1786 2.15319C11.4912 2.46575 11.6667 2.88968 11.6667 3.33171V7.49837Z' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
                    <path d='M14.9999 7.49854H16.6666C17.1086 7.49854 17.5325 7.67413 17.8451 7.98669C18.1577 8.29925 18.3333 8.72317 18.3333 9.1652V18.3319L14.9999 14.9985H9.99992C9.55789 14.9985 9.13397 14.8229 8.82141 14.5104C8.50885 14.1978 8.33325 13.7739 8.33325 13.3319V12.4985' strokeWidth='1.25' strokeLinecap='round' strokeLinejoin='round' />
                </g>
                <defs>
                    <clipPath id='clip0_369_3250'>
                        <rect width='20' height='20' fill='white' transform='translate(0 -0.00146484)' />
                    </clipPath>
                </defs>
            </svg>

        </div>
    );
};

export const AddPetIcon = ({ fill }) => {
    return (
        <svg className='mx-2' width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M7.40084 7.92061C8.82474 7.92061 9.98309 6.3582 9.98309 4.43405C9.85211 -0.180247 4.94918 -0.179447 4.8186 4.4341C4.8186 6.3582 5.97694 7.92061 7.40084 7.92061Z' fill={fill} />
            <path d='M4.57659 8.35477C3.16026 4.7453 -0.767857 6.1793 0.466636 9.85181C1.75031 13.3181 5.80764 12.2163 4.57659 8.35477Z' fill={fill} />
            <path d='M14.6138 7.92061C16.0377 7.92061 17.196 6.3582 17.196 4.43405C17.065 -0.180247 12.1621 -0.179447 12.0315 4.4341C12.0315 6.3582 13.1899 7.92061 14.6138 7.92061Z' fill={fill} />
            <path d='M18.4935 11.8538C21.3979 12.7129 23.0757 7.33079 20.4946 6.35077C17.6839 5.4958 15.8497 10.7924 18.4935 11.8538Z' fill={fill} />
            <path d='M18.0113 13.2631C15.145 13.063 12.9901 16.1786 14.2632 18.797C16.0326 22.5196 21.6371 21.3143 21.6787 17.1498C21.6774 16.1567 21.2971 15.2016 20.6156 14.4793C19.9341 13.757 19.0026 13.322 18.0113 13.2631ZM19.4852 17.5347H18.1729V18.847C18.1731 18.8976 18.1633 18.9478 18.144 18.9946C18.1247 19.0414 18.0964 19.084 18.0606 19.1199C18.0249 19.1557 17.9824 19.1842 17.9357 19.2036C17.8889 19.223 17.8387 19.233 17.7881 19.233C17.7375 19.233 17.6873 19.223 17.6406 19.2036C17.5938 19.1842 17.5513 19.1557 17.5156 19.1199C17.4798 19.084 17.4515 19.0414 17.4322 18.9946C17.4129 18.9478 17.4031 18.8976 17.4033 18.847V17.5347H16.091C16.0404 17.5349 15.9902 17.525 15.9434 17.5058C15.8965 17.4865 15.854 17.4582 15.8181 17.4224C15.7822 17.3867 15.7538 17.3442 15.7344 17.2974C15.7149 17.2507 15.705 17.2005 15.705 17.1499C15.705 17.0992 15.7149 17.0491 15.7344 17.0023C15.7538 16.9556 15.7822 16.9131 15.8181 16.8773C15.854 16.8416 15.8965 16.8133 15.9434 16.794C15.9902 16.7747 16.0404 16.7649 16.091 16.7651H17.4033V15.4566C17.4036 15.3548 17.4443 15.2572 17.5164 15.1853C17.5886 15.1134 17.6863 15.073 17.7881 15.073C17.89 15.073 17.9877 15.1134 18.0598 15.1853C18.1319 15.2572 18.1726 15.3548 18.1729 15.4566V16.765H19.4852C19.5865 16.7663 19.6831 16.8074 19.7542 16.8795C19.8254 16.9515 19.8652 17.0487 19.8652 17.1499C19.8652 17.2511 19.8254 17.3483 19.7542 17.4203C19.6831 17.4923 19.5865 17.5334 19.4852 17.5347Z' fill={fill} />
            <path d='M17.0406 12.5546C17.2171 12.5205 17.3959 12.4999 17.5756 12.4931C15.0746 8.35719 8.81594 7.68907 5.488 11.1925C3.19076 13.2675 1.56172 19.247 6.05764 19.5663L9.15159 19.5662C9.76181 19.5469 10.6137 19.3466 10.6217 18.6195V15.4793C10.6237 15.3786 10.6651 15.2826 10.737 15.2121C10.809 15.1416 10.9057 15.102 11.0065 15.102C11.1073 15.102 11.204 15.1416 11.276 15.2121C11.3479 15.2827 11.3893 15.3786 11.3913 15.4794V18.6196C11.3997 19.3473 12.2505 19.5459 12.8614 19.5663L13.8081 19.5662C13.3588 18.8405 13.1228 18.003 13.1269 17.1495C13.122 16.0422 13.5141 14.9697 14.2321 14.1267C14.9502 13.2837 15.9466 12.7259 17.0406 12.5546Z' fill={fill} />
        </svg>
    );
};

export const AddPetCareHistoryIcon = () => {
    return (
        <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <g clipPath='url(#clip0_4745_22672)'>
                <path d='M14.25 0H2.75C1.23 0 0 1.23 0 2.75V18.25C0 19.77 1.23 21 2.75 21H9.34C8.8 19.86 8.5 18.59 8.5 17.25C8.5 16.1 8.72 15 9.13 13.99C9.09 14 9.05 14 9 14H4C3.45 14 3 13.55 3 13C3 12.45 3.45 12 4 12H9C9.38 12 9.72 12.22 9.88 12.54C10.53 11.53 11.37 10.67 12.36 10H4C3.45 10 3 9.55 3 9C3 8.45 3.45 8 4 8H13C13.55 8 14 8.45 14 9C14 9.05 14 9.09 13.99 9.13C14.92 8.75 15.94 8.53 17 8.51V2.75C17 1.23 15.77 0 14.25 0ZM8 6H4C3.45 6 3 5.55 3 5C3 4.45 3.45 4 4 4H8C8.55 4 9 4.45 9 5C9 5.55 8.55 6 8 6Z' fill='#9A6051' />
                <path d='M17.25 10.5C13.528 10.5 10.5 13.528 10.5 17.25C10.5 20.972 13.528 24 17.25 24C20.972 24 24 20.972 24 17.25C24 13.528 20.972 10.5 17.25 10.5ZM20 18.25H18.25V20C18.25 20.552 17.802 21 17.25 21C16.698 21 16.25 20.552 16.25 20V18.25H14.5C13.948 18.25 13.5 17.802 13.5 17.25C13.5 16.698 13.948 16.25 14.5 16.25H16.25V14.5C16.25 13.948 16.698 13.5 17.25 13.5C17.802 13.5 18.25 13.948 18.25 14.5V16.25H20C20.552 16.25 21 16.698 21 17.25C21 17.802 20.552 18.25 20 18.25Z' fill='#9A6051' />
            </g>
            <defs>
                <clipPath id='clip0_4745_22672'>
                    <rect width='24' height='24' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export const CallIconButton = ({ className = '' }) => {
    return (
        <div className={className}>
            <svg className='size-full stroke-current' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                <path d='M18.3334 14.0999V16.5999C18.3344 16.832 18.2868 17.0617 18.1939 17.2744C18.1009 17.487 17.9645 17.6779 17.7935 17.8348C17.6225 17.9917 17.4206 18.1112 17.2007 18.1855C16.9809 18.2599 16.7479 18.2875 16.5168 18.2666C13.9525 17.988 11.4893 17.1117 9.32511 15.7083C7.31163 14.4288 5.60455 12.7217 4.32511 10.7083C2.91676 8.53426 2.04031 6.05908 1.76677 3.48325C1.74595 3.25281 1.77334 3.02055 1.84719 2.80127C1.92105 2.58199 2.03975 2.38049 2.19575 2.2096C2.35174 2.03871 2.54161 1.90218 2.75327 1.80869C2.96492 1.7152 3.19372 1.6668 3.42511 1.66658H5.92511C6.32953 1.6626 6.7216 1.80582 7.02824 2.06953C7.33488 2.33324 7.53517 2.69946 7.59177 3.09992C7.69729 3.89997 7.89298 4.68552 8.17511 5.44158C8.28723 5.73985 8.31149 6.06401 8.24503 6.37565C8.17857 6.68729 8.02416 6.97334 7.80011 7.19992L6.74177 8.25825C7.92807 10.3445 9.65549 12.072 11.7418 13.2583L12.8001 12.1999C13.0267 11.9759 13.3127 11.8215 13.6244 11.755C13.936 11.6885 14.2602 11.7128 14.5584 11.8249C15.3145 12.107 16.1001 12.3027 16.9001 12.4083C17.3049 12.4654 17.6746 12.6693 17.9389 12.9812C18.2032 13.2931 18.3436 13.6912 18.3334 14.0999Z' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M11.7083 1.6665C13.4068 1.84548 14.9934 2.59884 16.2056 3.80199C17.4179 5.00514 18.1832 6.58601 18.3749 8.28317' strokeLinecap='round' strokeLinejoin='round' />
                <path d='M11.7083 5C12.5278 5.16161 13.2799 5.56586 13.8669 6.16026C14.4539 6.75466 14.8486 7.51177 14.9999 8.33333' strokeLinecap='round' strokeLinejoin='round' />
            </svg>

        </div>
    );
};

export const PetHospitalIcon = ({ className = '' }) => {
    return (
        <div className={className}>
            <svg className='size-full' viewBox='0 0 14 13' xmlns='http://www.w3.org/2000/svg'>
                <path d='M7.91992 9.07926C7.91992 10.2656 8.88678 11.2308 10.0753 11.2308C11.2637 11.2308 12.2306 10.2656 12.2306 9.07926C12.2306 7.8929 11.2637 6.92773 10.0752 6.92773C8.88676 6.92773 7.91992 7.89292 7.91992 9.07926ZM8.85801 8.36612C8.85801 8.20759 8.98674 8.07909 9.14555 8.07909C9.30435 8.07909 9.43308 8.2076 9.43308 8.36612C9.43308 8.52465 9.30435 8.65315 9.14555 8.65315C8.98674 8.65315 8.85801 8.52465 8.85801 8.36612ZM10.7855 10.2832C10.6208 10.3929 10.4132 10.413 10.2302 10.3369C10.1213 10.2916 9.99476 10.2916 9.88573 10.3369C9.81231 10.3675 9.73482 10.3825 9.65774 10.3825C9.54327 10.3825 9.42964 10.3493 9.33112 10.2843C9.16814 10.1767 9.06999 9.99633 9.06857 9.80183V9.79974L9.06855 9.79457C9.06855 9.53014 9.17189 9.28169 9.35951 9.09502C9.54635 8.90914 9.79428 8.80689 10.0579 8.80689H10.0613C10.5858 8.80862 11.0183 9.21908 11.046 9.74133C11.047 9.75893 11.0474 9.77676 11.0474 9.79428C11.0475 9.99112 10.9496 10.174 10.7855 10.2832ZM11.0049 8.07909C11.1637 8.07909 11.2925 8.2076 11.2925 8.36612C11.2925 8.52465 11.1637 8.65315 11.0049 8.65315C10.8461 8.65315 10.7174 8.52463 10.7174 8.36612C10.7174 8.2076 10.8461 8.07909 11.0049 8.07909ZM10.3455 7.97611C10.3455 8.13465 10.2168 8.26315 10.058 8.26315C9.8992 8.26315 9.77046 8.13463 9.77046 7.97611C9.77046 7.81758 9.8992 7.68908 10.058 7.68908C10.2168 7.68908 10.3455 7.81758 10.3455 7.97611Z' />
                <path d='M10.0584 8.38087C9.94816 8.38029 9.84301 8.42334 9.76442 8.50152C9.68586 8.57971 9.64258 8.68374 9.64258 8.79448L9.6426 8.79757C9.64261 8.80031 9.64261 8.80229 9.64734 8.80539C9.65498 8.81044 9.66097 8.80795 9.66351 8.80689C9.78851 8.75493 9.92085 8.72859 10.0569 8.72859C10.193 8.72859 10.3253 8.75493 10.4502 8.80685C10.4533 8.80811 10.4583 8.81024 10.4653 8.80561C10.4713 8.80161 10.4713 8.79775 10.4713 8.79432C10.4713 8.78673 10.4711 8.77922 10.4707 8.77168C10.4593 8.55687 10.2743 8.38157 10.0584 8.38087Z' />
                <path d='M7.34426 9.07849C7.34426 7.5756 8.56912 6.3529 10.0747 6.3529C10.6098 6.3529 11.1093 6.50748 11.5312 6.77407V5.02109C11.5312 4.86256 11.4024 4.73406 11.2436 4.73406H8.79228V2.28703C8.79228 2.1285 8.66354 2 8.50474 2H6.02645C5.86765 2 5.73891 2.12852 5.73891 2.28703V4.73406H3.28754C3.12873 4.73406 3 4.86258 3 5.02109V7.49499C3 7.65352 3.12873 7.78202 3.28754 7.78202H5.73889V10.229C5.73889 10.3876 5.86763 10.5161 6.02643 10.5161H7.75597C7.49521 10.0985 7.34426 9.60579 7.34426 9.07849Z' />
            </svg>

        </div>
    );
};

export const ShopPhoneIcon = ({ className = '' }) => {
    return (
        <div className={className}>
            <svg className='size-full' viewBox='0 0 13 13' xmlns='http://www.w3.org/2000/svg'>
                <path d='M10.4162 8.26628L9.04032 8.10919C8.87854 8.09019 8.71456 8.1081 8.5607 8.16158C8.40684 8.21506 8.2671 8.30271 8.15198 8.41794L7.15532 9.41461C5.61774 8.63241 4.36793 7.38261 3.58573 5.84503L4.58782 4.84294C4.82073 4.61003 4.93448 4.28503 4.89657 3.95461L4.73948 2.58961C4.70889 2.32533 4.58211 2.08154 4.38332 1.90472C4.18452 1.72791 3.92762 1.63043 3.66157 1.63086H2.72448C2.1124 1.63086 1.60323 2.14003 1.64115 2.75211C1.92823 7.37794 5.62782 11.0721 10.2482 11.3592C10.8603 11.3971 11.3695 10.8879 11.3695 10.2759V9.33878C11.3749 8.79169 10.9632 8.33128 10.4162 8.26628Z' />
            </svg>

        </div>
    );
};
