import { Button } from '@/components/common/Button';
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuTrigger } from '@/components/common/DropdownMenu';
import { SORT_OPTION } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { cn } from '@/utils/utils';
import classNames from 'classnames';
import { MoveVertical } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export default observer(function SortShop() {
    const defaultLabel = 'Sort';
    // hook
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    // store
    const { uiStore: { currentLocation, devicesScreen: { mobile } } } = useStore();

    // state
    const [open, setOpen] = useState(false);
    const [activeOption, setActiveOption] = useState<string>('');
    const [currentOptionLabel, setCurrentOptionLabel] = useState<string>(defaultLabel);

    const sortOptions = useMemo(() => {
        if (!currentLocation) {
            return Object.keys(SORT_OPTION).filter(key => SORT_OPTION[key].value !== SORT_OPTION.Nearby.value).map(key => SORT_OPTION[key]);
        }
        return Object.keys(SORT_OPTION).map(key => SORT_OPTION[key]);
    }, [currentLocation]);

    const sortLabel = Object.keys(SORT_OPTION).map(key => SORT_OPTION[key].label);

    // lifecycle
    useEffect(() => {
        setOpen(false);
    }, [mobile]);

    useEffect(() => {
        const sortParams = searchParams.get('sort');
        if (sortParams) {
            setActiveOption(sortParams);
            const optionLabel = sortOptions.find(item => item.value === sortParams)?.label || defaultLabel;
            setCurrentOptionLabel(optionLabel);
        } else {
            setCurrentOptionLabel(defaultLabel);
        }
    }, [searchParams, sortOptions]);
    // function
    const handleSelectSORT_OPTION = (value: string) => {
        const newParams = new URLSearchParams(searchParams);

        if (value === activeOption) {
            setActiveOption('');
            newParams.delete('sort');
        } else {
            newParams.set('sort', value.toString());
        }
        setSearchParams(newParams);
        setOpen(false);
    };

    return (
        <>
            <DropdownMenu open={open} onOpenChange={setOpen}>
                <DropdownMenuTrigger asChild>
                    <Button className='md:h-full h-8 md:rounded-[18px] rounded-[16px] flex gap-1 justify-center items-center flex-row px-2.5 bg-button-1 text-black font-medium'>
                        <MoveVertical className={classNames(sortLabel.includes(currentOptionLabel) ? 'text-label-5' : 'text-text-24')} />
                        <span className={cn('text-text-24', sortLabel.includes(currentOptionLabel) && 'text-label-5 font-semibold')}>{sortLabel.includes(currentOptionLabel) ? t(`select_options.${currentOptionLabel}`) : t('button.sort')}</span>
                    </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className='w-56'>
                    {sortOptions.map((item, index) => {
                        return (
                            <DropdownMenuCheckboxItem
                                key={index}
                                checked={activeOption === item.value}
                                onClick={() => handleSelectSORT_OPTION(item.value)}
                            >
                                {t(`select_options.${item.label}`)}
                            </DropdownMenuCheckboxItem>
                        );
                    })}
                </DropdownMenuContent>
            </DropdownMenu>
        </>

    );
});
