import { Carousel, CarouselContent, CarouselItem } from '@/components/common/Carousel';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/common/Tabs';
import { useStore } from '@/hooks/useStore';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MenuPriceComponent from './components/MenuPriceComponent';
import ReviewComponent from './components/ReviewComponent';
import { convertNumber } from '@/utils/utils';
import { useLocation } from 'react-router-dom';

export default observer(function ShopDetailReview() {
    // hooks
    const { t } = useTranslation();
    const reviewRef = useRef<HTMLDivElement>(null);
    const menuPriceRef = useRef<HTMLDivElement>(null);
    const location = useLocation();

    // store
    const {
        uiStore: { devicesScreen: { mobile, desktop } },
        shopStore: { shopDetail }
    } = useStore();

    // state
    const [activeTab, setActiveTab] = useState('Reviews Tab');

    // life cycle
    // useEffect(() => {
    //     if (location.hash === '#review') {
    //         reviewRef.current?.scrollIntoView({ behavior: 'smooth' });
    //         reviewRef.current?.focus();
    //     }
    // }, [location]);

    // function
    const scrollToComponent =
        (
            ref: React.RefObject<HTMLDivElement>,
            headerHeightMobile: number,
            headerHeightDesktop: number
        ) => {
            if (ref.current) {
                ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
                if (mobile) {
                    window.scrollBy(0, -headerHeightMobile);
                } else {
                    window.scrollBy(0, -headerHeightDesktop);
                }
            }
        };

    const handleTabChange = (tabValue: string) => {
        setActiveTab(tabValue);
        if (tabValue === t('words_title.tab_reviews')) {
            scrollToComponent(reviewRef, 100, 150);
        } else if (tabValue === t('words_title.tab_menu_price')) {
            scrollToComponent(menuPriceRef, 0, 50);
        }
    };

    const totalReviews = Object.values(shopDetail?.totalReviewsByRating ?? 0).reduce((total, count) => total + count, 0);

    return (
        <div
            className='bg-white rounded pb-16'
            ref={reviewRef}
            tabIndex={-1}
        >
            <div className='w-full h-1 md:h-0 bg-background-2'></div>
            <Tabs value={activeTab} onValueChange={handleTabChange} className='w-full h-full flex flex-col' defaultValue={t('words_title.tab_reviews')}>
                {desktop && (
                    <TabsList variant='createShop' data-orientation='horizontal' className='flex w-full overflow-x-auto rounded sticky top-[7.125rem] bg-white z-20'>
                        <TabsTrigger
                            variant='createShop'
                            className='md:text-[0.875rem] text-3 w-full'
                            value='Reviews Tab'
                        >
                            {`${t('words_title.tab_reviews')} (${convertNumber(totalReviews)})`}
                        </TabsTrigger>
                        <TabsTrigger
                            variant='createShop'
                            className='md:text-[0.875rem] text-3 w-full'
                            value='Menu Price Tab'
                        >
                            {t('words_title.tab_menu_price')}
                        </TabsTrigger>
                    </TabsList>
                )}
                {mobile && (
                    <TabsList variant='createShop' data-orientation='horizontal' className='w-full border-b border-b-border-1 px-2 sticky top-[3.125rem] bg-white z-20'>
                        <Carousel
                            opts={{
                                align: 'start'
                            }}
                            className='w-full'
                        >
                            <CarouselContent className='flex snap-x snap-mandatory ml-0'>
                                <CarouselItem className='pl-0 basis-1/2 snap-center flex-shrink-0'>
                                    <TabsTrigger variant='createShop' className='md:text-[0.875rem] text-3 w-full' value='Reviews Tab'>{`${t('words_title.tab_reviews')} (${totalReviews})`}</TabsTrigger>
                                </CarouselItem>
                                <CarouselItem className='pl-0 basis-1/2 snap-center flex-shrink-0'>
                                    <TabsTrigger variant='createShop' className='md:text-[0.875rem] text-3 w-full' value='Menu Price Tab'>{t('words_title.tab_menu_price')}</TabsTrigger>
                                </CarouselItem>
                            </CarouselContent>
                        </Carousel>
                    </TabsList>
                )}
                <>
                    <TabsContent value='Reviews Tab'>
                        <ReviewComponent />
                    </TabsContent>
                    <TabsContent value='Menu Price Tab'>
                        <div className='pb-16 md:pb-0'>
                            <MenuPriceComponent />
                        </div>
                    </TabsContent>
                </>
            </Tabs>
        </div>
    );
});
