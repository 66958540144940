import { Button } from '@/components/common/Button';
import Paginate from '@/components/pagination/Paginate';
import { ROUTES, SORT_OPTION } from '@/configs/constants';
import { useStore } from '@/hooks/useStore';
import { TableState } from '@tanstack/react-table';
import { Heart } from 'lucide-react';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { flowResult } from 'mobx';
import StoreCard from '@/pages/home/components/StoreCard';
import { SearchShopParams } from '@/types/http-payload/shop';
import { scrollToTop } from '@/utils/utils';

export default observer(function MyFavoriteShop() {
    // hook
    const { t } = useTranslation();
    const navigate = useNavigate();

    // store
    const {
        shopStore: { allFavoriteShop, totalPage, getAllShop, paging, cleanAllShop, setObservable },
        authStore: { token },
        uiStore: { currentLocation, setObservable: setUIVarObservable }
    } = useStore();

    // lifecycle
    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setUIVarObservable('currentLocation', {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                }, { isMergeObject: true });
            });
        }

        return () => {
            setObservable('allFavoriteShop', [], { isMergeObject: false });
            cleanAllShop();
        };
    }, []);

    useEffect(() => {
        if (token) {
            getAllData(paging, false);
        }
    }, [token, currentLocation]);

    // function
    const getAllData = async (paging: TableState, hideLoading: boolean = true) => {
        let pagingReq: TableState = { ...paging };

        let params: SearchShopParams = {
            isFavorite: true
        };
        if (currentLocation) {
            params.location = currentLocation.latitude + ',' + currentLocation.longitude;
            pagingReq = {
                ...pagingReq,
                sorting: [
                    {
                        id: SORT_OPTION.Nearby.value,
                        desc: true
                    }
                ]
            };
        } else {
            pagingReq = {
                ...pagingReq,
                sorting: [
                    {
                        id: SORT_OPTION.StarRate.value,
                        desc: true
                    }
                ]
            };
        }

        const res = await flowResult(getAllShop(params, pagingReq, hideLoading));
        if (res) {
            setObservable('allFavoriteShop', res.elements, { isMergeObject: false });
        }
    };

    return (
        <>
            {
                allFavoriteShop.length > 0 && token && (
                    <div>
                        <div className='bg-background-2 md:bg-white px-0 md:px-4 md:py-6 grid point-920:grid-cols-2 grid-cols-1 2xl:gap-2 md:gap-4 gap-2'>
                            {allFavoriteShop.filter(shop => shop.isFavorite).map((store, index) => {
                                return (
                                    <>
                                        <StoreCard
                                            key={index}
                                            storeCard={store}
                                            callBackUpdateFavoriteFc={() => getAllData(paging)}
                                            callBackUpdateReviewFc={() => getAllData(paging)}
                                        />
                                    </>
                                );
                            })}
                        </div>
                        <div className='bg-white w-full flex justify-center py-6 md:pb-6 md:pt-0 border-t border-t-border-1 md:border-none'>
                            <Paginate
                                forcePage={paging.pagination.pageIndex}
                                pageCount={totalPage}
                                onPageChange={({ selected }) => {
                                    paging.pagination.pageIndex = selected;
                                    getAllData(paging);
                                    scrollToTop();
                                }}
                            />
                        </div>
                    </div>
                )
            }
            {
                allFavoriteShop.length === 0 && (
                    <div className='w-full text-center py-10'>
                        <div className='font-semibold text-[0.9375rem]'>{t('sentences.you_dont_have_save_shop')}</div>
                        <div className='mt-4 flex justify-center items-center text-small leading-[1.125rem]'>
                            {t('button.click')}
                            {' '}
                            <Heart className='w-5 h-5 cursor-pointer text-active mx-0.5' />
                            {t('sentences.save_your_favotire')}
                        </div>
                        <Button
                            onClick={() => navigate(ROUTES.search.href)}
                            className='h-[38px] w-[173px] mx-auto mt-5 md:flex items-center justify-center'
                        >
                            {t('button.start_searching')}
                        </Button>
                    </div>
                )
            }
        </>

    );
});
